export const YACHT_STATUS = {
  NEW: 'New',
  IN_USE: 'In Use',
  CONCEPT: 'Concept',
} as const;

export const YACHT_TYPE = {
  MOTOR: 'Motor',
  SAILING: 'Sailing',
  EXPEDITION: 'Expedition',
  GULET: 'Gulet',
  OPEN: 'Open',
  CATAMARAN: 'Catamaran',
  SPORTS_FISHING: 'Sports Fishing',
} as const;

export const VAT_STATUS = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  EXEMPT: 'Exempt',
} as const;

export const HULL_MATERIAL = {
  STEEL: 'Steel',
  ALUMINIUM: 'Aluminium',
  WOOD: 'Wood',
  FERROCEMENT: 'Ferrocement',
  FIBERGLASS: 'Fiberglass',
} as const;

export const DECK = {
  TEEK: 'Teek',
  NO_TEEK: 'No teek',
};

export const FUEL_TYPE = {
  DIESEL: 'Diesel',
  PETROL: 'Petrol',
  ELECTRIC: 'Electric',
  HYBRID: 'Hybrid',
} as const;

export const STABILIZATION = {
  ACTIVE_TANKS: 'Active tanks',
  GYRO_STABILIZERS: 'Gyro stabilizers',
  ACTIVE_FIN_STABILIZERS: 'Active fin stabilizers',
  NONE: 'None',
} as const;

export const CLASSIFICATION_SOCIETY = {
  ABS: 'American Bureau of Shipping',
  KR: 'Korean Register',
  BV: 'Bureau Veritas',
  LR: "Lloyd's Register",
  CCS: 'China Classification Society',
  NK: 'Nippon Kaiji Kyokai (Class NK)',
  CRS: 'Croatian Register of Shipping',
  PRS: 'Polish Register of Shipping',
  DNV_GL: 'DNV GL AS',
  RINA: 'RINA',
  IRS: 'Indian Register of Shipping',
  RS: 'Russian Maritime Register of Shipping',
} as const;

export const YACHT_INSTANCE_STATUS = {
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  DRAFTED: 'Drafted',
  SUSPENDED: 'Suspended',
} as const;

export const YEAR_BUILT = { MIN: 1900 };

export const GENERATOR_ELECTRICITY = [220, 380, 400, 110];
