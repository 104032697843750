import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import AdminsTable from './pages/AdminsTable';
import redirect from '../../modules/redirect';
import UnverifiedUsersTable from 'router/subrouters/Home/pages/UnverifiedUsersTable';
import UsersTable from 'router/subrouters/Home/pages/UsersTable';
import YachtsTable from 'router/subrouters/Home/pages/YachtsTable';

export default [
  {
    path: '/yachts',
    component: YachtsTable,
    exact: true,
  },
  {
    path: '/users',
    component: UsersTable,
    exact: true,
  },
  {
    path: '/unverified-users',
    component: UnverifiedUsersTable,
    exact: true,
  },
  {
    path: '/admins',
    component: AdminsTable,
    exact: true,
  },
  {
    path: '/',
    component: redirect('~users'),
  },
  {
    path: '*',
    component: redirect('/home'),
  },
] as Array<EnhancedRouteProps>;
