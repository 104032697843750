import httpClient from '../httpClient';
import { UnverifiedUser } from '../../models/UnverifiedUser';
import { PaginationResponse } from '../../models/PaginationResponse';

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<UnverifiedUser>>('users/temporary', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
    },
  });
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`users/temporary/${id}`);
}

export default {
  get,
  deleteOne,
};
