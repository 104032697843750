import auth from './auth';
import admins from './admins';
import users from './users';
import yachts from './yachts';

export default {
  auth,
  admins,
  users,
  yachts,
};
