import httpClient from '../httpClient';
import { User } from '../../models/User';
import { PaginationResponse } from '../../models/PaginationResponse';
import unverifiedUsers from './unverifiedUsers';

async function create(id: string) {
  return httpClient.post<{ user: User }>(`users/temporary/${id}`);
}

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<User>>('users', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
    },
  });
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`users/${id}`);
}

export default {
  create,
  get,
  deleteOne,
  unverifiedUsers,
};
