/* eslint-disable no-restricted-globals */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
  Select,
} from 'semantic-ui-react';
import './YachtsTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';
import { Yacht } from 'models/Yacht';
import DatePreview from 'components/DatePreview';
import env from 'env';
import { YACHT_INSTANCE_STATUS } from 'constants/yacht.constants';
import { YachtInstanceStatus } from 'models/Yacht';

type YachtsTableProps = {};

const YachtsTable: React.FC<YachtsTableProps> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const [statuses, setStatuses] = useState<YachtInstanceStatus[]>([
    YACHT_INSTANCE_STATUS.PUBLISHED,
    YACHT_INSTANCE_STATUS.UNPUBLISHED,
    YACHT_INSTANCE_STATUS.SUSPENDED,
  ]);
  const { items, loadItems, setItems, currentPage, totalPages } = usePagination<
    Yacht
  >(api.yachts.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, {
      $sort: '-_id',
      $searchString: searchInput,
      $relations: JSON.stringify(['user']),
      status: JSON.stringify({
        $in: statuses,
      }),
    });
  }, [searchInput, statuses, loadItems]);

  const onApprove = useCallback(
    async (yachtId: string) => {
      try {
        const {
          data: { yacht },
        } = await api.yachts.approveOne(yachtId);

        setItems((items) =>
          items.map((item) => (item._id === yachtId ? yacht : item)),
        );

        toast.success('Successfully deleted yacht.');
      } catch (err) {
        const error = err as any;
        toast.error(error?.response.data.message || error.message);
      }
    },
    [setItems],
  );

  const onDelete = useCallback(
    async (yachtId: string) => {
      try {
        await api.yachts.deleteOne(yachtId);

        setItems((items) => items.filter((item) => item._id !== yachtId));

        toast.success('Successfully deleted yacht.');
      } catch (err) {
        const error = err as any;
        toast.error(error?.response.data.message || error.message);
      }
    },
    [setItems],
  );

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Yachts</Header>
      <div className="table-actions">
        <Input
          focus
          placeholder="Search..."
          value={searchInput}
          onChange={(e, field) => setSearchInput(field.value)}
        />
        <Select
          options={Object.values(YACHT_INSTANCE_STATUS).map((yachtStatus) => ({
            text: yachtStatus,
            value: yachtStatus,
          }))}
          value={statuses}
          multiple
          onChange={(e, field) =>
            setStatuses(field.value as YachtInstanceStatus[])
          }
        />
      </div>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Url</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((yacht) => (
            <Table.Row key={yacht._id}>
              <Table.Cell>{yacht._id}</Table.Cell>
              <Table.Cell>{yacht.vesselName}</Table.Cell>
              <Table.Cell>
                {yacht.user?.firstName} {yacht.user?.lastName}
              </Table.Cell>
              <Table.Cell>{yacht.status}</Table.Cell>
              <Table.Cell>
                <a
                  href={`${env.MARKETPLACE_ENDPOINT}/yacht/${yacht._id}?token=${yacht.publicAccessToken}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </Table.Cell>
              <Table.Cell>
                <DatePreview>{yacht.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell
                onClick={(ev: any) => {
                  ev.stopPropagation();
                }}
                selectable={false}
              >
                {!yacht.isApproved && yacht.status !== 'Drafted' && (
                  <ConfirmPopup
                    confirm={() => onApprove(yacht._id)}
                    text="Are you sure you want to approve this yacht?"
                    buttonText="Approve"
                  >
                    <Button icon="check" color="green"></Button>
                  </ConfirmPopup>
                )}

                <ConfirmPopup
                  confirm={async () => {
                    if (
                      confirm(
                        'All matches and things will be deleted, are you sure?',
                      )
                    ) {
                      onDelete(yacht._id);
                    }
                  }}
                  text="Are you sure you want to remove this yacht?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default YachtsTable;
