import httpClient from '../httpClient';
import { Yacht } from '../../models/Yacht';
import { PaginationResponse } from '../../models/PaginationResponse';

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<Yacht>>('yachts', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
    },
  });
}

async function approveOne(id: string) {
  return httpClient.post<{ yacht: Yacht }>(`yachts/${id}/approve`, {
    isApproved: true,
  });
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`yachts/${id}`);
}

export default {
  get,
  approveOne,
  deleteOne,
};
