import { ObjectValues } from 'utils/types';

export const KYC_VERIFICATION_STATUS = {
  UNVERIFIED: 'Unverified',
  APPROVED: 'Verification approved',
  REJECTED: 'Verification rejected',
  SUBMITTED: 'Verification submitted',
  RESUBMISSION_REQUESTED: 'Verification resubmission requested',
  EXPIRED: 'Verification expired',
  ABANDOED: 'Verification abandoned',
} as const;

export const EMAIL_NOTIFICATION_SETTINGS = {
  MATCH: 'match',
  MATCH_DECAY: 'matchDecay',
  APPOINTMENT: 'appointment',
  CHAT_MESSAGE: 'chatMessage',
  CONTRACT_DEADLINE_REMINDERS: 'contractDeadlineReminders',
} as const;

export const VERIFF_SUBMIT_STATUSES = [
  'approved',
  'resubmission_requested',
  'declined',
  'expired',
  'abandoned',
] as const;

export const USER_TYPES = {
  BROKER: 'Broker',
  PRIVATE: 'Private',
} as const;

export const VERIFF_STATUS_MAPPING: Record<
  typeof VERIFF_SUBMIT_STATUSES[number],
  ObjectValues<typeof KYC_VERIFICATION_STATUS>
> = {
  approved: KYC_VERIFICATION_STATUS.APPROVED,
  resubmission_requested: KYC_VERIFICATION_STATUS.RESUBMISSION_REQUESTED,
  declined: KYC_VERIFICATION_STATUS.REJECTED,
  expired: KYC_VERIFICATION_STATUS.EXPIRED,
  abandoned: KYC_VERIFICATION_STATUS.ABANDOED,
};
