import React, { useCallback, useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
} from 'semantic-ui-react';
import './AdminsTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';
import { Admin } from 'models/Admin';
import AdminModal from 'router/subrouters/Home/pages/AdminsTable/components/AdminModal';
import DatePreview from 'components/DatePreview';

type AdminTableProps = {};

const AdminsTable: React.FC<AdminTableProps> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const { items, loadItems, setItems, currentPage, totalPages } = usePagination<
    Admin
  >(api.admins.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, {
      $sort: '-_id',
      $searchString: searchInput,
    });
  }, [searchInput, loadItems]);

  const onCreate = useCallback(
    async (adminData) => {
      const {
        data: { admin },
      } = await api.admins.create(adminData);
      setItems((items) => [admin, ...items]);
      toast.success('Successfully created admin.');
    },
    [setItems],
  );

  const onUpdate = useCallback(
    async (updateData: Partial<Admin>, changedAdmin: Admin) => {
      const {
        data: { admin },
      } = await api.admins.updateOne(changedAdmin._id, updateData);

      setItems((items) =>
        items.map((item) => (item._id === changedAdmin._id ? admin : item)),
      );
      toast.success('Successfully updated admin');
    },
    [setItems],
  );

  const onDelete = useCallback(
    async (admin: Admin) => {
      try {
        await api.admins.deleteOne(admin._id);
        setItems((items) => items.filter((item) => item._id !== admin._id));
        toast.success('Successfully deleted admin.');
      } catch (err) {
        const error = err as any;
        toast.error(error?.response.data.message || error.message);
      }
    },
    [setItems],
  );

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Admins</Header>
      <Input
        focus
        placeholder="Search..."
        value={searchInput}
        onChange={(e, field) => setSearchInput(field.value)}
      />
      <AdminModal onSubmit={onCreate} action="create">
        <Button floated="right">Create Admin</Button>
      </AdminModal>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((admin) => (
            <Table.Row key={admin._id}>
              <Table.Cell>{admin.name}</Table.Cell>
              <Table.Cell>{admin.email}</Table.Cell>
              <Table.Cell>
                <DatePreview>{admin.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell
                onClick={(ev: any) => {
                  ev.stopPropagation();
                }}
                selectable={false}
              >
                <AdminModal action="update" onSubmit={onUpdate} admin={admin}>
                  <Button icon="edit" color="blue"></Button>
                </AdminModal>
                <ConfirmPopup
                  confirm={() => onDelete(admin)}
                  text="Are you sure you want to remove this admin?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default AdminsTable;
