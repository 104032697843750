import httpClient from '../httpClient';
import { Admin } from '../../models/Admin';
import { PaginationResponse } from '../../models/PaginationResponse';

type CreateBody = {
  name: string;
  email: string;
  password: string;
};

async function create(body: CreateBody) {
  return httpClient.post<{ admin: Admin }>('admins', body);
}

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<Admin>>('admins', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
    },
  });
}

async function updateOne(id: string, body: Partial<CreateBody>) {
  return httpClient.patch<{ admin: Admin }>(`admins/${id}`, body);
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`admins/${id}`);
}

export default {
  create,
  get,
  updateOne,
  deleteOne,
};
