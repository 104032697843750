import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import {
  Container,
  Menu,
  Sidebar,
  Icon,
  Segment,
  SemanticICONS,
} from 'semantic-ui-react';
import { capitalize } from 'utils/string';
import Routes from 'router/components/Routes';
import routes from 'router/subrouters/Home/routes';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store/reducers';
import credentialsService from 'services/credentialsService';
import { FETCH_CURRENT_USER } from 'store/actions/actionTypes';
import { Admin } from 'models/Admin';

const HomeRouter: React.FC<RouteComponentProps> = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = useSelector<State, Admin>((state) => state.currentAdmin);

  const logOut = useCallback(() => {
    credentialsService.removeAuthBody();
    dispatch({ type: FETCH_CURRENT_USER, payload: null });
    history.push('/login');
  }, [dispatch, history]);

  const menuItems = useMemo(
    () =>
      [
        {
          link: '/home/users',
          icon: 'users',
          name: 'Users',
        },
        {
          link: '/home/yachts',
          icon: 'ship',
          name: 'Yachts',
        },
        {
          link: '/home/unverified-users',
          icon: 'user times',
          name: 'Unverified users',
        },
        {
          link: '/home/admins',
          icon: 'user secret',
          name: 'Admins',
        },
      ] as Array<{
        link: string;
        icon: SemanticICONS;
        name: string;
      }>,
    [],
  );
  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar as={Menu} icon="labeled" inverted vertical visible width="thin">
        {menuItems.map((item) => (
          <Menu.Item
            as={Link}
            to={item.link}
            active={item.link === location.pathname}
            header
          >
            <Icon name={item.icon} />
            {item.name}
          </Menu.Item>
        ))}
      </Sidebar>

      <Sidebar.Pusher className="home-page-content">
        <Segment basic>
          <Menu inverted size="massive">
            <Menu.Item>{capitalize(admin.name)}</Menu.Item>
            <Menu.Item onClick={logOut}>Log out</Menu.Item>
          </Menu>
          <Container className="routes-container">
            <Routes routes={routes} />
          </Container>
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default HomeRouter;
